<template>
    <div class="container">
        <!-- 组件 -->
        <div class="tools">
            <div :class="['toolitem', chooseset == index ? 'tool_active' : '']" v-for="(item, index) in componentArr" :key="index"
                @click="changeSet(index)">
                <img :src="imgH + 'diyComponentsImg/' + item.icon" alt="icon" />
                <p>{{ item.name }}</p>
            </div>
        </div>
        <!-- 展示 -->
        <div class="pane">
            <div class="pane_phone">
                <div class="pane_main">
                    <img :src="imgH + 'multiversion_phoneheader.png'" class="image">
                    <img :src="imgH + 'multiversion_index.png'" class="image">
                    <!-- 商家信息 -->
                    <div class="shopinfo">
                        <img :src="shopinfo.logo" alt="logo">
                        <div class="shopcenter">
                            <div class="shopname">{{ shopinfo.name }}</div>
                            <div class="description">{{ shopinfo.description }}</div>
                        </div>
                        <router-link class="shopright" to="/foodMulti/foodbrandstory"
                            :style="{ 'background': `linear-gradient(90deg, ${getLightColor(main_color)}, ${main_color})` }">品牌故事</router-link>
                    </div>
                    <!-- 公告栏 -->
                    <div :class="['notice', chooseset == 1 ? 'component_checked' : 'component_canset']"
                        :style="{ '--color': notice.color }" @click="changeSet(1)">
                        <img :src="notice.icon" alt="icon" v-if="notice.icon">
                        <span>{{ notice.text }}</span>
                    </div>
                    <!-- 优惠券 -->
                    <div class="coupon">
                        <div class="coupon_icon"><span>券</span></div>
                        <div class="coupon_item coupon_on">
                            <i class="iconfont icon-multiversion-coupon-on" :style="{ 'color': main_color }"></i>
                            <span style="margin-left: 4px;">满20减5</span>
                            <span style="margin-left: 24px;">领取</span>
                        </div>
                        <div class="coupon_item coupon_off" :style="{ '--color': main_color }">
                            <i class="iconfont icon-multiversion-coupon-off" :style="{ 'color': main_color }"></i>
                            <span style="margin-left: 4px;">满100减10</span>
                            <span style="margin-left: 10px;">已领</span>
                        </div>
                        <div style="flex:1;"></div>
                        <div class="coupon_go">去领券</div>
                        <img :src="imgH + 'rightArrow_1.png'" alt="rightArrow">
                    </div>
                    <!-- 商品 -->
                    <div class="goods">
                        <div class="goods_left thin_scroll_column">
                            <div :class="['goods_classify', item.id == classify_active ? 'classify_active' : '']"
                                v-for="item in goods_classify.list" :key="item.id" @click="changeClassify(item)"
                                :style="{ 'border-color': main_color }">
                                <img :src="item.icon" alt="icon" v-if="item.icon">
                                <div>{{ item.name }}</div>
                            </div>
                        </div>
                        <div class="goods_right thin_scroll_column">
                            <img :src="classify_image" alt="classify_image" class="classify_image">
                            <div class="classify_name">{{ classify_name }}</div>
                            <div v-if="is_empty" class="goods_empty">
                                <No />
                            </div>
                            <div class="goods_item" v-for="item in goods_list" :key="item.id">
                                <img :src="item.goods_picture || imgH + 'orderDefault.png'" alt="goods_picture"
                                    class="goods_picture">
                                <div class="goods_info">
                                    <div class="goods_name">{{ item.goods_name }}</div>
                                    <div class="goods_subtitle">{{ item.goods_subtitle }}</div>
                                    <div class="goods_bottom">
                                        <span :style="{ 'color': basic_settings.price_color }">
                                            <span class="fz11">￥</span>
                                            <span style="font-size:16px">{{ item.goods_price }}</span>
                                        </span>
                                        <span class="fz11 goods_prime_cost">
                                            <span>￥</span>
                                            <span>{{ item.goods_prime_cost }}</span>
                                        </span>
                                        <i :class="[basic_settings.cart_style == '0' ? 'el-icon-circle-plus' : 'iconfont icon-icon8', 'goods_cart']"
                                            :style="{ '--background': `linear-gradient(90deg, ${getLightColor(basic_settings.cart_color)}, ${basic_settings.cart_color})` }"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- tabbar -->
                    <img :src="imgH + 'multiversion_tabbar.png'" class="image" alt="multiversion_tabbar">
                </div>
            </div>
        </div>
        <!-- 设置 -->
        <div class="setting">
            <div class="setting_header">{{ componentArr[chooseset].name }}</div>
            <div class="setting_part">
                <basic v-if="chooseset == 0" :basic_settings="basic_settings" @reset-data="resetData"></basic>
                <notice v-if="chooseset == 1" :notice="notice" @reset-data="resetData"></notice>
                <classify v-if="chooseset == 2" :goods_classify="goods_classify"></classify>
            </div>
        </div>
    </div>
</template>

<script>
import { imgUrlHead } from '@/util/config.js'
import basic from '../components/basic.vue'
import notice from '../components/notice.vue'
import classify from '../components/classify.vue'
export default {
    components: {
        basic,
        notice,
        classify
    },
    data () {
        return {
            imgH: imgUrlHead,
            componentArr: [
                { name: '基础设置', icon: 'headerbg.png' },
                { name: '公告设置', icon: 'noticeBar.png' },
                { name: '商品分类', icon: 'allGoods.png' },
            ],
            basic_settings: {
                price_color: '#FA5052',
                cart_style: '0',//0--样式一 1--样式二
                cart_color: '#FFA115'
            },
            notice: {
                color: '#997F00',
                text: '祝您购物愉快！',
                icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/tips.png'
            },
            goods_classify: {
                is_default: '1',
                list: []
            },
            classify_active: 0,
            page: 1,
            goods_list: [],
            classify_image: '',
            classify_name: '',
            is_nomore: false,
            is_empty: false,
            chooseset: 0,
            hasdata: false
        }
    },
    created () {
        this.getGoodsClassify()
        this.getPageInfo()
    },
    computed: {
        shopinfo () {
            return this.$parent.shopinfo
        },
        main_color () {
            return this.$parent.main_color
        }
    },
    methods: {
        // 获取商品分类
        getGoodsClassify () {
            this.$axios.post(this.$api.goods.goodsClass, {
                is_show: 2
            }).then((res) => {
                if (res.code == 0) {
                    let list = res.result
                    list.forEach(item => {
                        item.icon = ''
                    })
                    this.goods_classify.list = list
                    if (list.length > 0) {
                        this.classify_active = list[0].id
                        this.classify_image = list[0].picture
                        this.classify_name = list[0].name
                        this.goods_list = []
                        this.is_nomore = false
                        this.is_empty = false
                        this.page = 1
                        this.getGoodsList()
                    }
                }
            })
        },
        // 获取商品列表
        getGoodsList () {
            this.$axios.post(this.$api.goods.list, {
                page: this.page,
                row: 10,
                classify_id: this.classify_active,
                is_putaway: 2
            }).then(res => {
                if (res.code == 0) {
                    let list = res.result.list
                    this.goods_list = [...list, ...this.goods_list]
                    if (list.length < 10) {
                        this.is_nomore = true
                    }
                    if (this.goods_list.length == 0) {
                        this.is_empty = true
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 改变选中分类
        changeClassify (item) {
            this.classify_active = item.id
            this.classify_image = item.picture
            this.classify_name = item.name
            this.goods_list = []
            this.is_nomore = false
            this.is_empty = false
            this.page = 1
            this.getGoodsList()
        },
        // 获取指点颜色的淡色
        getLightColor (color) {
            if (color) {
                let [a, b, c] = [color.substring(1, 3), color.substring(3, 5), color.substring(5, 7)]
                let [a2, b2, c2] = [parseInt(a, 16), parseInt(b, 16), parseInt(c, 16)]
                return `rgb(${a2},${b2 + 51},${c2 + 69})`
            } else {
                return null
            }

        },
        // 重置数据
        resetData (obj) {
            this[obj.data][obj.attr] = null
        },
        // 改变设置组件
        changeSet (index) {
            this.chooseset = index
        },
        // 获取装修数据
        getPageInfo () {
            this.$axios.post(this.$api.catering.diypageList, {
                type: 3 // 1-- 餐饮首页(单店) 2--品牌故事 3-- 餐饮首页(多店)
            }).then((res) => {
                if (res.code == 0) {
                    if (res.result.page_values) {
                        let info = res.result.page_values
                        this.basic_settings = info.basic_settings
                        this.notice = info.notice
                        this.goods_classify = info.goods_classify
                        this.hasdata = true
                    }
                }
            })
        },
        // 保存装修数据
        savePageInfo () {
            let info = {
                basic_settings: this.basic_settings,
                notice: this.notice,
                goods_classify: this.goods_classify
            }
            let data = {
                page_name: '餐饮首页',
                page_type: '3',
                show_type: '2,3,4,5,6,7',
                page_value: info
            }
            if (this.hasdata) {
                data.type = '3'
            }
            this.$axios.post(this.$api.catering.diypage, data).then((res) => {
                if (res.code == 0) {
                    this.$message.success('保存成功')
                    this.getPageInfo()
                } else {
                    this.$message.error(res.msg)
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.container {
    display: flex;
    height: 100%;

    .tools {
        flex: 0 0 300px;
        background-color: #fafafa;
        box-sizing: border-box;
        padding: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;

        .toolitem {
            width: 40%;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            margin-bottom: 20px;
            font-size: 14px;
            box-sizing: initial;

            &:hover {
                background-color: #E8F1FB;
            }
        }

        .tool_active {
            background-color: #E8F1FB;
        }
    }

    .pane {
        flex: 1;
        height: 100%;
        min-width: 411px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        margin-left: 6px;

        .pane_phone {
            width: 411px;
            height: 853px;
            margin-top: 6px;
            background-size: 100% 100%;
            background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/phonebg.png);
            position: relative;
        }

        .pane_main {
            width: 375px;
            height: 720px;
            margin: 60px 0 0 16px;
            display: flex;
            flex-direction: column;

            .shopinfo {
                margin-top: 8px;
                padding-left: 15px;
                display: flex;
                align-items: center;

                img {
                    width: 44px;
                    height: 44px;
                    border-radius: 22px;
                }

                .shopcenter {
                    margin-left: 9px;
                    flex: 1;

                    .shopname {
                        font-size: 18px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #292929;
                        line-height: 20px;
                    }

                    .description {
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                        line-height: 20px;
                    }
                }

                .shopright {
                    width: 74px;
                    height: 32px;
                    border-radius: 16px 0px 0px 16px;
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 32px;
                    text-align: center;
                }
            }

            .notice {
                margin: 10px 15px 0;
                width: 345px;
                height: 24px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                padding: 0 12px;
                box-sizing: border-box;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background: var(--color);
                    opacity: 0.04;
                    border-radius: 4px;
                }

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 4px;
                }

                span {
                    font-size: 11px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: var(--color);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .coupon {
                margin-top: 6px;
                display: flex;
                padding: 0 15px;
                align-items: center;

                .coupon_icon {
                    width: 20px;
                    height: 20px;
                    background: #FEE0B2;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 20px;
                    margin-right: 8px;

                    span {
                        font-size: 12px;
                        background-image: -webkit-linear-gradient(left, #FD9073, #FF4C3A);
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                .coupon_item {
                    width: 100px;
                    height: 20px;
                    border-radius: 4px;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        right: 29px;
                        top: 5px;
                        height: 11px;
                        width: 0;
                        border-right: 1px dashed #ffffff;
                        z-index: 1;
                    }

                    i {
                        font-size: 20px;
                        position: absolute;
                        top: -1px;
                    }

                    span {
                        position: relative;
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 20px;
                        transform: scale(0.83);
                        display: inline-block;
                    }
                }

                .coupon_on {
                    margin-right: 8px;
                }

                .coupon_off {
                    &::before {
                        border-color: var(--color);
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        background-color: var(--color);
                        opacity: 0.1;
                    }

                    span {
                        color: var(--color);
                    }
                }

                .coupon_go {
                    font-size: 11px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    margin-right: 4px;
                }

                img {
                    width: 10px;
                    height: 10px;
                    transform: rotate(90deg);
                }
            }

            .goods {
                flex: 1;
                margin-top: 12px;
                height: 0;
                display: flex;

                .goods_left {
                    width: 80px;
                    background-color: #F7F7FA;
                    height: 100%;
                    overflow-y: auto;

                    .goods_classify {
                        padding: 20px 0;
                        text-align: center;
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                        cursor: pointer;

                        img {
                            width: 16px;
                            height: 16px;
                            margin-bottom: 6px;
                        }
                    }

                    .classify_active {
                        background-color: #fff;
                        border-left: 2px solid #FF9100;
                        color: #292929;
                    }
                }

                .goods_right {
                    flex: 1;
                    padding: 0 15px 0 10px;
                    height: 100%;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;

                    .classify_image {
                        width: 100%;
                        object-fit: cover;
                        max-height: 120px;
                        border-radius: 8px;
                    }

                    .classify_name {
                        margin-top: 10px;
                        font-size: 11px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                    }

                    .goods_empty {
                        flex: 1;
                    }

                    .goods_item {
                        padding: 12px 0;
                        display: flex;
                        align-items: center;

                        .goods_picture {
                            width: 94px;
                            height: 94px;
                            border-radius: 8px;
                            margin-right: 12px;
                        }

                        .goods_info {
                            flex: 1;
                            width: 0;
                            display: flex;
                            flex-direction: column;
                            height: 100%;

                            .goods_name {
                                font-size: 15px;
                                font-family: Source Han Sans CN;
                                font-weight: 500;
                                color: #292929;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                line-height: 19px;
                            }

                            .goods_subtitle {
                                font-size: 10px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #999999;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                flex: 1;
                                margin-top: 8px;
                            }

                            .fz11 {
                                font-size: 11px;
                            }

                            .goods_bottom {
                                position: relative;

                                .goods_cart {
                                    position: absolute;
                                    bottom: 0;
                                    right: 0;
                                    font-size: 22px;

                                    &::before {
                                        background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                        background-image: var(--background);
                                    }

                                }
                            }

                            .goods_prime_cost {
                                text-decoration: line-through;
                                color: #999999;
                                margin-left: 4px;
                            }
                        }
                    }
                }
            }

            .image {
                width: 375px;
                height: auto;
            }
        }
    }

    .setting {
        flex-shrink: 0;
        width: 596px;
        height: 100%;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        padding-left: 20px;
        display: flex;
        flex-direction: column;

        .setting_header {
            border-bottom: 1px solid #eee;
            height: 70px;
            display: flex;
            align-items: center;
            padding: 0 30px;
        }

        .setting_part {
            font-size: 14px;
            flex: 1;
            overflow-y: auto;
        }
    }
}</style>
